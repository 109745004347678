import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    // const { data } = this.props
    // const siteTitle = data.site.siteMetadata.title

    return (
      <div className="page-404" key={this.props.location.pathname}>
        <SEO title="404: Not Found" />
        <h1>That page doesn't exist</h1>
        <a href="/" className="btn btn--home">
          <div className="btn__content">
            <span className="btn__inner">
              <span>
                Go home
              </span>
            </span>
          </div>
        </a>
      </div>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
